<template>
  <Button :label="label"
          :class="classes"
          :style="styles"
          :loading="isLoading"
          @click="handleClick($event)"
  />
</template>

<script>
export default {
  name: "BaseButton",

  props: {
    label: {
      type: String,
      default: 'Submit'
    },
    classes: {
      type: [String, Array, Object],
      default: ''
    },
    styles: {
      type: [String, Array, Object],
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
}
</script>

<style lang="scss">
.p-button {
  width: 100%;
  border-radius: 4px;
  border: none;
  &-lg {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.02em;
  }
  &:enabled:hover {
    opacity: .9;
  }
  &:enabled:focus {
    box-shadow: none;
  }
}
</style>