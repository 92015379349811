<template>
  <div class="pricing-plan-wrapper">
    <SelectedCurrencyButton v-if="currencyList.length > 1"
                            class="currency-button"
                            :value="selectedCurrency"
                            :options="currencyList"
                            @input="updateCurrency"
    />
    <div class="product-info">
      <img :src="selectedPricingPlan.product_info.image_url" alt="free" class="product-info-image">
      <div class="product-info-name">
        <span class="room">{{ selectedPricingPlan.product_info.name }}</span>
        <span v-if="selectedPricingPlan.bonus_info && selectedPricingPlan.bonus_info.coins > 0" class="bonus">
          {{ `+ ${selectedPricingPlan.bonus_info.coins} ${$t('checkout.coins') }` }}
        </span>
      </div>
      <div v-if="selectedPricingPlan.renewal_info" class="product-info-description">
        {{ `${$t('checkout.subscription_renewal') } ${selectedPricingPlan.renewal_info.next_billing_date}` }}
      </div>
      <div class="product-info-price">
        <div class="currency-price">
          <AppPrice :currency="selectedCurrency" :price="pricingPlan" />
          <span v-if="selectedPricingPlan.billing_period === 'monthly'" class="billing-period">{{ $t('checkout.monthly') }}</span>
          <span v-if="selectedPricingPlan.billing_period === 'annual'" class="billing-period">{{ $t('checkout.annual') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SelectedCurrencyButton from "@/main/components/payment/SelectedCurrencyButton";
import AppPrice from "@/components/app/AppPrice";

export default {
  name: "PricingPlan",

  components: { SelectedCurrencyButton, AppPrice },

  computed: {
    ...mapGetters('checkout', ['selectedPricingPlan', 'getCheckoutSettings']),

    selectedCurrency() {
      return this.getCheckoutSettings.currency?.value || 'USD';
    },

    currencyList() {
      return this.getCheckoutSettings.currency?.list || ['USD'];
    },

    pricingPlan() {
      return this.selectedPricingPlan.price;
    },
  },

  methods: {
    ...mapActions('checkout', ['updateCheckoutCurrency']),

    updateCurrency(newCurrency) {
      this.updateCheckoutCurrency(newCurrency);
    },
  }
}
</script>

<style lang="scss">
.pricing-plan-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  .currency-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }
  .product-info {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'image name price'
      'image description price';
    &-image {
      width: 72px;
      height: 72px;
      align-self: center;
      margin-right: 30px;
      grid-area: image;
    }
    &-name {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      grid-area: name;
      .room {
        margin-right: 5px;
        font-size: 20px;
        font-weight: 400;
        line-height: 27.24px;
        letter-spacing: 0.02em;
      }
      .bonus {
        margin-bottom: 2px;
        font-size: 12px;
        font-weight: 700;
        line-height: 17px;
        color: #ffbf00;
      }
    }
    &-description {
      align-self: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.79px;
      letter-spacing: 0.02em;
      color: #747474;
      grid-area: description;
    }
    &-price {
      display: flex;
      align-items: flex-end;
      grid-area: price;
      .currency-price {
        display: flex;
        align-items: baseline;
        margin-bottom: -8px;
        .price-wrapper {
          margin-right: 2px;
          font-size: 36px;
          font-weight: 600;
          line-height: 65.37px;
        }
        .billing-period {
          font-size: 24px;
          font-weight: 400;
          line-height: 32.68px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .pricing-plan-wrapper {
    flex-direction: column;
    position: relative;
    margin-bottom: 14px;
    .product-info {
      grid-template-areas:
      'image name name'
      'image price price'
      'description description description';
      &-image {
        margin-right: 20px;
      }
      &-name {
        position: relative;
        bottom: -13px;
      }
      &-price {
        .currency-price {
          .price-wrapper {
            font-size: 24px;
          }
          .billing-period {
            font-size: 12px;
          }
        }
      }
      &-description {
        margin-top: 2px;
        font-size: 13px;
      }
    }
  }
}
</style>