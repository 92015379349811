<template>
  <BaseButton
    :label="$t('checkout.pay_now')"
    :classes="['p-button-lg']"
    :styles="'width: 100%; background-color: #40BD5E;'"
    @click="buyByPayPal"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { PaymentService } from "@/main/services/service_payment";
import BaseButton from "@/components/UI/BaseButton";

export default {
  name: "PayPalForm",

  components: { BaseButton },

  data() {
    return {
      paymentUrl: process.env.VUE_APP_BASE_URL + '/payment/init.php',
    };
  },

  computed: {
    ...mapGetters('checkout', ['selectedPricingPlan', 'getCheckoutPageId'])
  },

  methods: {
    buyByPayPal() {
      const paymentData = {
        product: this.selectedPricingPlan.product_id,
        pricing_plan: this.selectedPricingPlan.pricing_plan_id,
        source: 'paypal',
        page_id: this.getCheckoutPageId,
        csrf: window.csrf
      };

      PaymentService.redirectWithForm(this.paymentUrl, paymentData);
    }
  }
};
</script>
