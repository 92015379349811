import axios from 'axios';

let api = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_WEB_API + '/catalog',
    headers: {
        'X-CSRF-TOKEN' : window.csrf || '',
        'Accept' : 'application/json'
    }
})

api.interceptors.response.use(function (response) {
    console.log('service_catalog.js. . response: ', response);
    return response;
}, function (error) {
    console.log('service_catalog.js. error: ', error);
    return Promise.reject(error);
});

export const CatalogService  = {
    async getRoomSubscriptionsData () {
        try {
            const response = await api.get('/room-subscriptions');
            return response.data;
        } catch (error) {
            console.error('Error fetching room subscriptions:', error);
            throw error;
        }
    }
}
