<template>
  <div class="base-input">
    <label v-if="label" :style="customStyle" class="base-input-label">{{ label }}</label>
    <InputText
      :placeholder="placeholder"
      :class="[ customClass, { 'input-error': error } ]"
      :style="customStyle"
      :disabled="disabled"
      :readonly="readonly"
      v-bind="$attrs"
      v-on="$listeners"
      @input="onInput"
    />
    <span v-if="error" :style="customStyle" class="error-message">{{ error }}</span>
  </div>
</template>

<script>

export default {
  name: "BaseInput",

  innerAttrs: false,

  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: ''
    },
    customStyle: {
      type: Object,
      default: () => ({})
    },
    error: {
      type: String,
      default: null,
    }
  },

  methods: {
    onInput(event) {
      this.$emit('change', event)
    }
  }
}
</script>

<style scoped lang="scss">
.base-input {
  font-family: 'Open Sans', sans-serif;
  &-label {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    color: #9598A3;
    font-size: 10px;
    line-height: 13.62px;
    letter-spacing: 0.014em;
    font-weight: 400;
  }
  input {
    display: block;
    height: 39px;
    width: 100%;
    padding: 0 40px 0 10px;
    border: 1px solid #ccc;
    color: #545454;
    background-color: #fff;
    outline: 0;
    font-weight: 400;
    font-size: 14px;
    transition: border-color .2s;
    &::placeholder {
      color: #9A9A9A;
    }
    &:hover:not(:disabled) {
      border-color: #A5A5A5;
    }
    &:focus:not(:disabled) {
      border-color: #2ECB4E;
    }
    &:disabled {
      background-color: #E3E3E3;
    }
  }
  .input-error {
    margin-top: 4px;
    border-color: #FF6B6B;
  }
  .error-message {
    color: #FF6B6B;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>