<template>
  <form class="card-form-wrapper" @submit.prevent>
    <div class="form-element">
      <BaseInput v-model="form.email"
                 class="form-input"
                 :label="$t('checkout.email_label')"
                 :placeholder="$t('checkout.email_label')"
                 :custom-style="{'font-family': 'Arial',}"
                 :error="errors.email"
                 type="email"
                 @blur="handleEmailBlur"
      />
    </div>

    <div id="address-element" class="address-element" />

    <div class="form-element">
      <label class="stripe-card-label">{{ $t('checkout.card_label') }}</label>
      <div id="card-element" />
      <span v-if="errors.card" class="stripe-card-error">{{ errors.card }}</span>
    </div>

    <BaseButton ref="payNowButton"
                :label="$t('checkout.pay_now')"
                :classes="['p-button-lg']"
                :styles="'width: 100%; background-color: #40BD5E; margin-top: 10px;'"
                :disabled="isLoading"
                @click="buyByCreditCard"
    />
  </form>
</template>

<script>
import BaseInput from "@/components/UI/BaseInput";
import BaseButton from "@/components/UI/BaseButton";
import { loadStripe } from '@stripe/stripe-js';
import { PaymentService } from "@/main/services/service_payment";
import { mapGetters } from "vuex";

export default {
  name: "CreditCardForm",

  components: { BaseInput, BaseButton },

  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      stripe: null,
      elements: null,
      addressElement: null,
      cardElement: null,
      stripeClasses: {
        base: 'stripe-element-base',
        complete: 'stripe-element-complete',
        empty: 'stripe-element-empty',
        focus: 'stripe-element-focus',
        invalid: 'stripe-element-invalid',
        webkitAutofill: 'stripe-element-webkit-autofill',
      },
      elementStyle: {
        base: {
          width: '100%',
          height: '40px',
          border: '1px solid red',
          padding: '11px',
          '::placeholder': {
            color: '#9A9A9A',
          },
        },
        invalid: {
          color: '#FF6B6B',
          borderColor: '#FF6B6B',
          boxShadow: 'none',
        },
      },
      form: {
        email: '',
        billing_details: {},
      },
      errors: {
        email: null,
        address: null,
        card: null
      },
      isLoading: false
    };
  },

  computed: {
    ...mapGetters('checkout', ['selectedPricingPlan', 'getCheckoutPageId']),
  },

  mounted() {
    this.initializeStripe()
  },

  methods: {
    async initializeStripe() {
      try {
        this.stripe = await loadStripe(this.publishableKey);
        const appearance = {
          rules: {
            '.Label': {
              display: 'block',
              width: '100%',
              marginBottom: '8px',
              color: '#9598A3',
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '13.62px',
              letterSpacing: '0.014em'
      },
            '.Input': {
              paddingTop: '11px',
              paddingBottom: '11px',
              color: '#545454',
              border: '1px solid #ccc',
              borderRadius: '3px',
              boxShadow: 'none',
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '14px',
              fontWeight: '400',
            },
            '.Input::placeholder': {
              color: '#9A9A9A',
            },
            '.Input:focus:hover': {
              borderColor: '#2ECB4E',
            },
            '.Input:focus': {
              borderColor: '#2ECB4E',
              boxShadow: 'none',
            },
            '.Input:hover': {
              borderColor: '#A5A5A5',
            },
            '.Input--invalid': {
              color: '#FF6B6B',
              borderColor: '#FF6B6B',
              boxShadow: 'none',
            },
            '.Error': {
              color: '#FF6B6B',
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '12px',
              lineHeight: '16px',
              marginTop: '4px',
            }
          }
        };

        this.elements = this.stripe.elements({ appearance });
        this.addressElement = this.elements.create('address', { mode: 'billing' });
        this.addressElement.mount('#address-element');

        this.cardElement = this.elements.create('card', {
          style: this.elementStyle,
          classes: this.stripeClasses
        });
        this.cardElement.mount('#card-element');
        this.cardElement.addEventListener('change', (event) => {
          if (event.error) {
            this.errors.card = event.error.message
          } else {
            this.errors.card = null
          }
        });
      } catch (error) {
        console.error('Error initializing Stripe:', error);
      }
    },

    validateEmail(email) {
      const result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return result.test(email);
    },

    handleEmailBlur() {
      if (this.validateEmail(this.form.email)) {
        this.errors.email = null;
      }
    },

    async initStripePayment() {
      const paymentData = {
        product: this.selectedPricingPlan.product_id,
        pricing_plan: this.selectedPricingPlan.pricing_plan_id,
        source: 'credit_card',
        page_id: this.getCheckoutPageId,
        csrf: window.csrf,
        stripe_email: this.form.email
      };

      return await PaymentService.initStripePayment(paymentData);
    },

    completeStripePayment(init_response) {
      if (init_response.status === 'success') {
        let transaction_data = init_response.transaction_data;
        let method = transaction_data.mode === 'setup' ? 'handleCardSetup' : 'handleCardPayment';
        let options = {
          payment_method_data: {
            billing_details: this.form.billing_details,
          }
        }
        let args = [transaction_data.client_secret, this.cardElement, options];

        this.stripe[method](...args).then((result) => {
          if (result.error) {
            this.errors.card = result.error.message;
          } else {
            PaymentService.toResultPage({ status: 'success', result: 1 });
          }
        });
      } else {
        PaymentService.toResultPage({ status: 'error', result: 0 });
      }
    },

    buyByCreditCard() {
      this.isLoading = true;
      this.errors.email = this.validateEmail(this.form.email) ? null : this.$t('checkout.invalid_email_address');

      this.addressElement.getValue()
          .then((result) => {
            if (!result.complete) {
              throw new Error("Address incomplete");
            }

            this.errors.address = null;

            if (this.errors.email || this.errors.address || this.errors.card) {
              throw new Error("Form validation error");
            }

            this.form.billing_details = result.value;
            this.form.billing_details.email = this.form.email;

            this.initStripePayment()
              .then((data) => {
                if (data.redirect_uri) {
                  window.location = data.redirect_uri;
                  return;
                }
                this.completeStripePayment(data.response);
              })
              .catch((error) => {
                console.log('Payment error', error);
                PaymentService.toResultPage({ status: 'error', result: 0 });
              })
              .finally(() => {
                this.isLoading = false;
              });
          })
          .catch((error) => {
            console.log('Validation error', error);
            this.errors.address = error;
            this.isLoading = false;
          })
    }
  }
}
</script>

<style lang="scss">
.card-form-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 12px;
  margin: 24px 0 30px;
  .form-element {
    flex: 1 1 100%;
  }
  .stripe-card-label {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    color: #9598A3;
    font-family: 'Arial', sans-serif;
    font-size: 10px;
    line-height: 13.62px;
    letter-spacing: 0.014em;
    font-weight: 400;
  }
  .stripe-element-base {
    height: 40px;
    padding: 11px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: none;
    font-family: 'Arial', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #545454;
    &:hover {
      border-color: #A5A5A5;
    }
  }
  .stripe-element-focus {
    border-color: #2ECB4E;
    &:hover {
      border-color: #2ECB4E;
    }
  }
  .stripe-element-invalid {
    color: #FF6B6B;
    border-color: #FF6B6B;
    box-shadow: none;
  }
  .stripe-card-error {
    position: relative;
    bottom: -3px;
    color: #FF6B6B;
    font-family: 'Arial', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  .address-element {
    width: 100%;
  }
}
</style>
