<template>
  <div class="tabs">
    <ul class="tab-headers">
      <li v-for="(systemType, index) in system"
          :key="index"
          :class="{ active: activeTab === systemType }"
          @click="setActiveTab(systemType)"
      >
        <img v-if="getPaymentIcon(systemType)"
             :src="getPaymentIcon(systemType)"
             :alt="systemType"
             class="payment-image"
        >
        <span>{{ getPaymentLabel(systemType) }}</span>
        <div class="tab-indicator" />
      </li>
    </ul>
    <div class="tab-content">
      <div v-show="activeTab === 'stripe'" ref="stripeForm">
        <TermsInfo />
        <CreditCardForm ref="creditCardForm" />
      </div>
      <div v-show="activeTab === 'paypal'">
        <TermsInfo />
        <PayPalForm />
      </div>
      <div v-for="systemType in otherPaymentSystems" v-show="activeTab === systemType" :key="systemType">
        <TermsInfo />
        <OtherPaymentForm :system-type="systemType" />
      </div>
    </div>
  </div>
</template>

<script>
import CreditCardForm from "@/main/components/payment/CreditCardForm";
import PayPalForm from "@/main/components/payment/PayPalForm";
import OtherPaymentForm from "@/main/components/payment/OtherPaymentForm";
import { mapGetters } from "vuex";
import TermsInfo from "./TermsInfo.vue";

export default {
  name: "CheckoutTabs",

  components: { TermsInfo, CreditCardForm, PayPalForm, OtherPaymentForm },

  data() {
    return {
      system: [],
      activeTab: ''
    };
  },

  computed: {
    ...mapGetters('checkout', ['selectedPricingPlan']),

    paymentSystems() {
      return this.selectedPricingPlan.payment_systems || [];
    },

    otherPaymentSystems() {
      return this.paymentSystems.filter(systemType => !['stripe', 'paypal'].includes(systemType));
    }
  },

  watch: {
    paymentSystems(newValue) {
      this.system = newValue;
      if (!this.system.includes(this.activeTab)) {
        this.activeTab = this.system.length > 0 ? this.system[0] : '';
      }
    }
  },

  mounted() {
    this.system = this.paymentSystems;
    if (this.system.length > 0) {
      this.activeTab = this.system[0];
    }
  },

  methods: {
    getPaymentIcon(paymentSystem) {
      switch (paymentSystem) {
        case 'stripe':
          return require('@/assets/images/icons/credit-cart.svg');
        case 'paypal':
          return require('@/assets/images/icons/paypal.svg');
        default:
          return '';
      }
    },

    getPaymentLabel(paymentSystem) {
      switch (paymentSystem) {
        case 'stripe':
          return this.$t('checkout.credit_card');
        case 'paypal':
          return this.$t('checkout.paypal');
        default:
          return this.$t('checkout.other_payment');
      }
    },

    setActiveTab(systemType) {
      this.activeTab = systemType;
      if (systemType === 'stripe') {
        this.scrollToStripeForm();
      }
    },

    scrollToStripeForm() {
      this.$nextTick(() => {
        const payNowButton = this.$refs.creditCardForm.$refs.payNowButton.$el;
        if (payNowButton) {
          const offset = payNowButton.getBoundingClientRect().top + window.pageYOffset - 20;
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      });
    }
  },
};
</script>

<style lang="scss">
.tab-headers {
  display: flex;
  column-gap: 16px;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ccc;
  list-style-type: none;
  overflow-x: auto;
  white-space: nowrap;
  li {
    flex: 0 0 auto;
    height: 44px;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 10px 10px 10px 0;
    cursor: pointer;
    &.active {
      font-weight: 600;
      .tab-indicator {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    .tab-indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #2F2F2F;
      width: 100%;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.2s ease;
    }
  }
  .payment-image {
    margin-right: 12px;
  }
}


@media screen and (max-width: 540px) {
  .tab-headers {
    border-bottom: none;
  }
}
</style>
