<template>
  <div class="selected-button-wrapper">
    <SelectButton v-model="selectedCurrency" :options="options" @input="handleChange" />
  </div>
</template>

<script>
export default {
  name: "SelectedCurrencyButton",

  props: {
    value: {
      type: String,
      default: "USD",
      required: true
    },
    options: {
      type: Array,
      default:() => ["USD"],
      required: true
    }
  },

  data() {
    return {
      selectedCurrency: this.value
    };
  },

  watch: {
    value(newValue) {
      this.selectedCurrency = newValue;
    }
  },

  methods: {
    handleChange() {
      this.$emit('input', this.selectedCurrency);
    }
  }
}
</script>

<style lang="scss">
.selected-button-wrapper {
  .p-selectbutton {
    height: 27px;
    display: flex;
    padding: 2px;
    background-color: #F0F0F0;
    border-radius: 50px;
    transition: .2s;
    .p-button {
      height: 23px;
      display: inline-block;
      padding: 2px 11px;
      border-radius: 50px;
      color: white;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: .2s;
      span {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .p-selectbutton.p-buttonset .p-button:first-of-type {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .p-selectbutton.p-buttonset .p-button:last-of-type {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .p-selectbutton .p-button.p-highlight {
    transition: background-color 0.3s, transform 0.3s;
    background-color: #66C4FF;
    border: none;
  }

  .p-selectbutton .p-button.p-highlight:hover {
    background-color: #66C4FF;
    border: none;
  }
  .p-selectbutton .p-button:not(.p-highlight) {
    border: none;
    background-color: transparent;
    color: #565656;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #F0F0F0;
  }
}

</style>
