<template>
  <div class="table-cell">
    <span v-if="status === true"><img src="@/assets/images/icons/success.svg" alt="success"></span>
    <span v-else-if="status === false"><img class="red-cross" src="@/assets/images/icons/close_red.svg" alt="close"></span>
    <span v-else-if="typeof status === 'number'">
      {{ status }} {{ $tc(`checkout.${feature.feature_name}`, status) }}
    </span>
    <span v-else-if="feature.feature_name === 'coloured_room_name'">
      <span :style="{ color: status }">
        {{ $t(`checkout.${feature.feature_name}`) }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "FeatureStatus",
  props: {
    feature: {
      type: Object,
      required: true,
    },
    tier: {
      type: String,
      required: true,
    }
  },
  computed: {
    status() {
      return this.feature[this.tier];
    }
  }
};
</script>

<style lang="scss">
.table-cell {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  vertical-align: middle;
  border-top: 1px solid #e0e0e0;
}
.red-cross {
  width: 15px;
}
</style>
