<template>
  <p v-if="selectedPricingPlan.renewal_info" class="checkout-form-text">
    {{ $t('checkout.terms') }} <a :href="link + '/terms.phtml'" target="_blank">{{ $t('checkout.terms_link') }}</a>
  </p>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TermsInfo",

  data() {
    return {
      link: process.env.VUE_APP_BASE_URL
    };
  },

  computed: {
    ...mapGetters('checkout', ['selectedPricingPlan']),
  }
};
</script>

<style>
.checkout-form-text {
  margin: 18px 0;
  color: #9598A3;
  font-size: 14px;
  line-height: 19.07px;
  letter-spacing: 0.02em;
  a {
    color: #2F2F2F;
    border-bottom: 1px solid #2F2F2F;
  }
}
</style>
