<template>
  <div class="checkout-form-wrapper">
    <PricingPlan />

    <CheckoutTabs ref="checkoutTabs" />

    <p v-if="selectedPricingPlan.renewal_info" class="checkout-form-text">
      {{ $t('checkout.cancel_subscription') }}
    </p>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import PricingPlan from "@/main/components/payment/PricingPlan";
import CheckoutTabs from "@/main/components/payment/CheckoutTabs";

export default {
  name: "CheckoutForm",

  components: { CheckoutTabs, PricingPlan },

  computed: {
    ...mapGetters('checkout', ['selectedPricingPlan']),
  },
}
</script>

<style lang="scss">
.checkout-form-wrapper {
  padding: 24px;
  border-radius: 36px;
  background-color: #fff;
  max-width: 720px;
  margin: 0 auto;

  .p-tabview .p-tabview-panels {
    margin-top: 24px;
    padding: 0;
  }
  .checkout-form-btn {
    width: 100%;
  }
  .checkout-form-text {
    margin: 18px 0;
    color: #9598A3;
    font-size: 14px;
    line-height: 19.07px;
    letter-spacing: 0.02em;
  }
}
@media screen and (max-width: 768px) {
  .checkout-form-wrapper {
    padding: 15px;
  }
}
</style>
