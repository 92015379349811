import axios from 'axios';

let api = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_BASE_URL + '/payment',
    headers: {
        'X-CSRF-TOKEN': window.csrf || '',
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

api.interceptors.response.use(
    response => {
        console.log('service_payment.js. response: ', response);
        return response;
    },
    error => {
        console.log('service_payment.js. error: ', error);
        return Promise.reject(error);
    }
);

export const PaymentService = {
    async initStripePayment(billing_data) {
        try {
            const response = await api.post('/init.php', billing_data);
            console.log(response)
            return response.data;
        } catch (error) {
            console.error('Error initializing Stripe payment:', error);
            throw error;
        }
    },

    toResultPage(data) {
        this.redirectWithForm(process.env.VUE_APP_BASE_URL + '/purchase.php', data);
    },

    redirectWithForm(action, obj_data) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = action;
        form.enctype = 'application/x-www-form-urlencoded';

        for (const name in obj_data) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            input.value = obj_data[name];
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
    }
};
