<template>
  <div class="room-subscription-table-wrapper">
    <div v-if="!isMobile" class="desktop-table">
      <TableHeader :tier="freeTier" :is-current-tier="isCurrentTier(freeTier.tier_id)" class="free-header" />
      <TableHeader :tier="plusTier"
                   :is-current-tier="isCurrentTier(plusTier.tier_id)"
                   :price="plusTierPrice"
                   :currency="currency"
                   class="plus-header"
                   @upgrade="scrollToCheckoutForm"
      />

      <div class="table-column features">
        <FeatureCell v-for="feature in features" :key="feature.feature_name" :feature="feature" />
      </div>

      <div class="table-column free-features">
        <FeatureStatus v-for="feature in features"
                       :key="feature.feature_name"
                       :feature="feature"
                       tier="free"
        />
      </div>

      <div class="table-column plus-features">
        <FeatureStatus v-for="feature in features"
                       :key="feature.feature_name"
                       :feature="feature"
                       tier="plus"
        />
      </div>

      <div class="bg-column" />
    </div>

    <div v-else class="mobile-table">
      <swiper ref="swiper"
              :options="swiperOption"
      >
        <swiper-slide>
          <div class="slide-content">
            <TableHeader ref="plusHeader"
                         :tier="plusTier"
                         :is-current-tier="isCurrentTier(plusTier.tier_id)"
                         :price="plusTierPrice"
                         :currency="currency"
                         class="tier-header plus-header"
                         @upgrade="scrollToCheckoutForm"
            />
            <div class="table-column features">
              <FeatureCell v-for="feature in features" :key="feature.feature_name" :feature="feature" />
            </div>
            <div class="table-column sub-features">
              <FeatureStatus v-for="feature in features"
                             :key="feature.feature_name"
                             :feature="feature"
                             tier="plus"
              />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide-content">
            <TableHeader ref="freeHeader"
                         :tier="freeTier"
                         :is-current-tier="isCurrentTier(freeTier.tier_id)"
                         class="tier-header free-header"
            />
            <div class="table-column features">
              <FeatureCell v-for="feature in features" :key="feature.feature_name" :feature="feature" />
            </div>
            <div class="table-column sub-features">
              <FeatureStatus v-for="feature in features"
                             :key="feature.feature_name"
                             :feature="feature"
                             tier="free"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TableHeader from '@/main/components/payment/table/TableHeader';
import FeatureCell from '@/main/components/payment/table/FeatureCell';
import FeatureStatus from '@/main/components/payment/table/FeatureStatus';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: "RoomSubscriptionTable",
  components: { TableHeader, FeatureCell, FeatureStatus, Swiper, SwiperSlide },

  data() {
    return {
      isMobile: false,

      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 15,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },

  computed: {
    ...mapGetters('room_subscriptions', ['getRoomTiersInfo', 'getFeaturesByType']),
    ...mapGetters('checkout', ['getCheckoutSettings']),

    features() {
      return this.getRoomTiersInfo.features || [];
    },
    freeTier() {
      return this.getRoomTiersInfo.tiers.free || {};
    },
    plusTier() {
      return this.getRoomTiersInfo.tiers.plus || {};
    },
    currency() {
      return this.getCheckoutSettings.currency.value || 'USD';
    },
    plusTierPrice() {
      const pricing = this.plusTier.pricing;
      return pricing[this.currency] ? pricing[this.currency].price : pricing.USD.price;
    },
  },

  mounted() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    isCurrentTier(tierId) {
      return this.getRoomTiersInfo.current_tier_info.tier_id === tierId;
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },

    scrollToCheckoutForm() {
      this.$emit('scroll-to-checkout-form');
    },

    setFreeHeaderHeight() {
      const plusHeader = this.$refs.plusHeader.$el;
      const freeHeader = this.$refs.freeHeader.$el;
      if (plusHeader && freeHeader) {
        freeHeader.style.height = `${plusHeader.offsetHeight}px`;
      }
    },

    handleResize() {
      this.checkIsMobile();
      this.$nextTick(() => {
        if (this.isMobile) {
          this.setFreeHeaderHeight();
        }
      });
    },
  }
};
</script>

<style lang="scss">
.room-subscription-table-wrapper {
  padding: 24px;
  .desktop-table {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-template-areas:
      '. plus-header free-header'
      'features plus-features free-features';
    .free-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      grid-area: free-header;
      z-index: 1;
      .tier-title {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 32.68px;
        letter-spacing: 0.016em;
      }
    }
    .plus-header {
      grid-area: plus-header;
      z-index: 1;
      .tier-title {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.79px;
        letter-spacing: 0.016em;
      }
    }
    .features {
      grid-area: features;
    }
    .free-features {
      grid-area: free-features;
    }
    .plus-features {
      grid-area: plus-features;
    }
    .features, .free-features, .plus-features {
      display: grid;
      z-index: 1;
    }
    .bg-column {
      height: 100%;
      width: 100%;
      grid-area: 1 / 2 / 3 / 3;
      background: #EFF7F5;
      border-radius: 30px;
      z-index: 0;
    }
    .swiper-pagination-bullet {
      display: none;
    }
  }

  .mobile-table {
    position: relative;
    .slide-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'tier-header tier-header'
        'features sub-features';
      padding: 20px;
      background: #fff;
      border-radius: 36px;
      box-shadow: 2px 4px 12px 0 #2F2F2F0A;
      .tier-header {
        padding: 14px;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.79px;
        letter-spacing: 0.016em;
        grid-area: tier-header;
        &.free-header {
          display: flex;
          flex-direction: column;
        }
      }
      .features {
        grid-area: features;
      }
      .sub-features {
        grid-area: sub-features;
      }
    }
    .swiper-pagination {
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        margin: 0 2px;
        background: #66C4FF;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .room-subscription-table-wrapper {
    background: #fff;
    border-radius: 36px;
    box-shadow: 2px 4px 12px 0 #2F2F2F0A;
  }
}
@media screen and (max-width: 768px) {
  .room-subscription-table-wrapper {
    padding: 15px;
    .mobile-table {
      .swiper-container {
        max-width: 450px;
        .slide-content {
          padding: 7px;
          font-size: 14px;
          .table-cell {
            height: 36px;
            padding: 6px;
            text-align: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 468px) {
  .room-subscription-table-wrapper {
    .mobile-table {
      .swiper-container {
        .slide-content {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
