<template>
  <div class="checkout-room">
    <div v-if="isLoading" class="loader-wrapper">
      <Loader />
    </div>
    <div v-else>
      <section class="checkout-room-subscriptions">
        <RoomSubscriptionTable @scroll-to-checkout-form="scrollToCheckoutForm" />
      </section>
      <section ref="checkoutFormSection" class="checkout-room-plan" :style="isLoading ? 'padding-bottom: 0' : 'padding-bottom: 100px' ">
        <h2>{{ $t('checkout.selected_plan') }}</h2>
        <checkout-form />
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CatalogService } from "@/main/services/service_catalog";
import Loader from "@/components/Loader";
import RoomSubscriptionTable from "@/main/components/payment/RoomSubscriptionTable";
import CheckoutForm from "@/main/components/payment/CheckoutForm";

export default {
  name: "Room",
  components: { RoomSubscriptionTable, CheckoutForm, Loader },

  data() {
    return {
      isLoading: false
    }
  },

  async created() {
    await this.loadRoomSubscriptions();
    this.setCheckoutPageId('room');
  },

  methods: {
    ...mapActions('room_subscriptions', ['setRoomTiersInfo']),
    ...mapActions('checkout', ['setCheckoutSettings', 'setPricingPlans', 'setCheckoutPageId']),

    async loadRoomSubscriptions() {
      this.isLoading = true
      try {
        const data = await CatalogService.getRoomSubscriptionsData()

        if (!data.pricing_plans.length) {
          throw new Error('No pricing plans available');
        }

        this.setRoomTiersInfo(data?.room_tiers_info)
        this.setCheckoutSettings(data?.checkout_settings)
        this.setPricingPlans(data?.pricing_plans)
      } catch (error) {
        console.error('Failed to load data:', error)
        this.toRoomSettingsPage()
      } finally {
        this.isLoading = false
      }
    },

    scrollToCheckoutForm() {
      const checkoutFormSection = this.$refs.checkoutFormSection;
      if (checkoutFormSection) {
        checkoutFormSection.scrollIntoView({ behavior: 'smooth' });
      }
    },

    toRoomSettingsPage() {
      window.location = process.env.VUE_APP_PROFILES_BASE_URL + '/room_settings.php'
    }
  }
}
</script>

<style scoped lang="scss">
.checkout-room {
  h2 {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 600;
    line-height: 43.58px;
    letter-spacing: 0.02em;
    color: #2F2F2F;
    text-align: center;
  }
  &-subscriptions {
    margin-top: 120px;
    margin-bottom: 172px;
  }
  .green-check {
    color: green;
  }
  .red-cross {
    color: red;
  }
}

@media screen and (min-width: 768px) and (max-width: 976px) {
  .checkout-room-subscriptions {
    padding: 0 15px;
  }
}
@media screen and (max-width: 976px) {
  .checkout-room {
    &-subscriptions {
      margin-top: 50px;
      margin-bottom: 96px;
    }
    &-plan {
      padding: 0 15px;
    }
    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
</style>
