<template>
  <div class="price-wrapper">
    <span>{{ currencySymbol }}</span>
    <span>{{ price }}</span>
  </div>
</template>

<script>
export default {
  name: "AppPrice",

  props: {
    price: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },

  computed: {
    currencySymbol() {
      const currencyMap = {
        'USD': '$',
        'THB': '฿',
        'EUR': '€',
        'PHP': '₱',
        'IDR': 'Rp',
        'MYR': 'RM',
        'GBP': '£'
      };
      return currencyMap[this.currency] || '';
    }
  }
}
</script>

<style scoped>

</style>