<template>
  <div class="table-header">
    <div class="tier-title">
      {{ tier.name }}
    </div>
    <div v-if="isCurrentTier" class="tier-subtitle">
      {{ $t('checkout.your_current_tier') }}
    </div>
    <div v-if="price && currency" class="row-price">
      <AppPrice :currency="currency" :price="price" /> {{ $t('checkout.monthly') }}
    </div>
    <div v-if="tier.pricing && tier.pricing.billing_period === 'annual'" class="row-period">
      {{ $t('checkout.billed_annually') }}
    </div>
    <BaseButton v-if="tier.pricing"
                :label="$t('checkout.upgrade')"
                class="upgrade-btn"
                @click="$emit('upgrade')"
    />
  </div>
</template>

<script>
import AppPrice from "@/components/app/AppPrice";
import BaseButton from "@/components/UI/BaseButton";

export default {
  name: "TableHeader",
  components: { AppPrice, BaseButton },
  props: {
    tier: {
      type: Object,
      required: true,
    },
    isCurrentTier: {
      type: Boolean,
      required: true,
    },
    price: {
      type: Number,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
  }
};
</script>

<style lang="scss">
.table-header {
  padding: 24px;
  text-align: center;
  .tier-subtitle {
    margin: 5px 0 7px;
    font-size: 18px;
    font-weight: 700;
    color: #40BD5E;
  }
  .row-price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 16px 0 4px;
    .price-wrapper {
      margin-right: 2px;
      font-size: 28px;
      font-weight: 600;
      color: #2F2F2F;
    }
  }
  .row-period {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    letter-spacing: 0.02em;
    color: #747474;
  }
  .upgrade-btn {
    max-width: 240px;
    margin: 18px auto 0;
    background: #40BD5E;
    &.p-button:enabled:hover {
      background: #40BD5E;
      border: 1px solid #40BD5E;
    }
  }
  &.free-header {
    .tier-title {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tier-subtitle {
      height: 100%;
      align-content: flex-end;
    }
  }
}
@media screen and (max-width: 768px) {
  .table-header {
    .tier-subtitle {
      font-size: 16px;
    }
    .row-price {
      margin-bottom: 0;
    }
  }
}
</style>
