<template>
  <div class="table-cell">
    {{ $tc(`checkout.${feature.feature_name}`, feature.plus ) }}
  </div>
</template>

<script>
export default {
  name: "FeatureCell",
  props: {
    feature: {
      type: Object,
      required: true,
    }
  }
};
</script>

<style lang="scss">
.table-cell {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  vertical-align: middle;
  border-top: 1px solid #e0e0e0;
}
</style>
